@use "constants";
@use "mixins";
@use "mediaqueries";

html{
    overflow-y: auto;
}

body{
    color: constants.$text-color;
    @include mixins.default-font;
}

a{
	color: constants.$text-color;
}

h1, h2, h3{
	margin:0;
	font-weight:normal;
	@include mixins.fontsize(16);
}

.page-bg{
    background: #f6f4ef;
}

.page-header{
	padding: 5px 0 0 0;
}

.logo-link{
	color:#000;
	display:inline-block;
	text-decoration:none;
	@include mixins.fontsize(24);
	text-transform: lowercase;
    font-family: 'Amatic SC';
}

.main{
	position:relative;
	background: #fff;
	padding-bottom: 15px;
    border: 3px solid #bcb;
    border-width: 1px 0;

	.has-nav{
		padding-top:40px;
	}
}

.h1{
    margin-bottom: 10px;
    @include mixins.fontsize(18);
}

.page-heading{
    @include mixins.fontsize(20);
}

.module-heading{
    @include mixins.fontsize(18);
}

h2{
	font-weight: normal;
	@include mixins.fontsize(18);
    margin: 0;
}
.h2{
    margin-bottom: 10px;
}

h3{
    font-weight: normal;
	@include mixins.fontsize(16);
}

td,
th{
	white-space: nowrap;
}

dl{
	margin:0;
}

ul{
	margin:0;
}

p{
	margin: 15px 0 0 0;
}

pre {
    background: #f6f4ef;
    padding: 10px;
    border: solid 1px #bcb;
    overflow: hidden;

    code {
        white-space: pre;
        padding: 0;
        font-family: Consolas,Courier,monospace;
        font-size: 14px;
    }
}

.wide-wrap{
    margin: 10px auto;
}

@include mediaqueries.widthXSmall {
    .page-header{
        padding-top: 15px;
    }
    
    .logo-link{
        @include mixins.fontsize(32);
    }

    .page-heading {
        @include mixins.fontsize(22);
    }

    .module-heading {
        @include mixins.fontsize(18);
    }
}

@include mediaqueries.widthSmall {
	body{
		margin-bottom: 15px;
	}

    .logo {
        display: block;
	    padding: 0 0 15px 0;
    }
}

@include mediaqueries.widthLarge {
    .wrap{
        
    }

    .logo {
        margin-left: 0;
    }
}