@use "mediaqueries";

.table-list__column-header--sortable{
    .table-list__column-header__content{
        text-decoration: underline;
        cursor: pointer;
    }
}

.table-list__column-header--selected{
    .table-list__column-header__content{
        font-weight: bold;
    }
}
