@use "mediaqueries";

.page-section {
    max-width: 981px;
    margin: 0 auto;
    padding: 0 15px;
}

.page-section--wide {
    max-width: none;
    margin: 0 auto;
}

.block{
	margin-top: 15px;
}

@include mediaqueries.widthSmall {
    .page-section{
        display: flex;
        justify-content: space-between;
    }
    .region{
        flex: 1;
        width: 32%;
    }
    .region.width2{
        flex: 2;
        order: 1;
    }
    .region.aside {
        padding-left: 15px;
        order: 2;
    }
}
