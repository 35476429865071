@use "mediaqueries";
@use "mixins";

.amount{
	text-align: right;
}

.pos-result{
	color:green;
}

.neg-result{
	color:red;
}

@include mediaqueries.widthXSmall {
	.add-cashgame-button{
		position:absolute;
		top:15px;
		left:300px;
	}
}