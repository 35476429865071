@use "mixins";

.value-list{
}

.value-list__key{
	@include mixins.fontsize(12);
	text-transform: uppercase;
}

.value-list__value{
	margin: 0 0 10px 0;
}