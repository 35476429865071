@use "mixins";

.matrix{
    overflow:auto;
}

.matrix__value{
	display:block;
}

.matrix__transaction{
	@include mixins.fontsize(12);
    display:block;
}

.matrix__winner{
	background: #e7ede7;
}