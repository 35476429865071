
.player-row {
  padding: 5px 0;
  border-bottom: 1px solid #eee;
}

.player-row__row-wrapper {
  display: flex;
}

.player-row__link {
  padding: 8px;
}

.player-row__name-and-time {
  flex: 9;
}

.player-row__name {
  margin-right: 0.5rem;
}

.player-row__amounts {
  flex: 7;
}

.player-row__chart,
.player-row__actions {
  padding: 8px;
}

.player-row__small-chart {
  flex: 4;
}
