@use 'mixins';

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

label {
  display: block;
  @include mixins.default-font;
  margin: 0;
}

input[type='text'],
input[type='number'],
input[type='password'],
input[type='date'],
input[type='datetime'],
input[type='email'] {
  border: solid 1px #9e9e9e;
  @include mixins.default-font;
  padding: 4px 9px;
}

.form {
  overflow: hidden;

  .field {
    float: left;
  }
  .field + .field {
    margin-left: 4px;
  }
  .buttons {
    float: left;
    margin: 14px 0 0 4px;
  }
}

.buttons {
  margin-top: 15px;
}

.validation-error {
  color: red;
}

input.numberfield {
  width: 50px;
  text-align: right;
}

input.textfield {
  width: 170px;
}

input.longfield {
  width: 400px;
}

.checkbox-layout {
  position: relative;

  input {
    position: absolute;
    left: 0;
    top: 4px;
  }

  label {
    margin-left: 20px;
  }
}
