/* For modern browsers */
.clearfix:before,
.clearfix:after {
	content:"";
	display:table;
}
.clearfix:after {
	clear:both;
}
/* For IE 6/7 (trigger hasLayout) */
.clearfix {
	zoom:1;
}