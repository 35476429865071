@use 'mixins';

.button {
  border: 1px solid #ccc;
  color: #000;
  background: #fff;
  display: inline-block;
  @include mixins.default-font;
  font-weight: normal;
  padding: 6px 10px;
  text-decoration: none;
  white-space: nowrap;
  @include mixins.fontsize(12);
  text-transform: uppercase;
}

.button + .button {
  margin-left: 5px;
}

button[disabled] {
  opacity: 0.4;
  cursor: auto;
}

.button--icon {
  border: none;
  background: transparent;
  display: inline-block;
  @include mixins.default-font;
  @include mixins.fontsize(10);
  padding: 4px 9px;
  text-align: center;

  i {
    display: block;
    @include mixins.fontsize(28, 10);
  }
}

.button--action {
  border: 1px solid #bcb;
  background: #f6f4ef;
}

.button--warning {
}
