@use "mediaqueries";
@use "mixins";

nav{
    @include mixins.default-font;
    overflow: hidden;

	ul{
		list-style-type: none;
		padding: 0;
		margin: 0;
	}

	h2{
		margin: 0;
	}

	.selected{
		font-weight: bold;
	}
}

.user-nav {
    padding: 15px 0;

    ul {
        padding: 0;
    }

    li {
        float: left;
        margin: 0 1em 0 0;
    }

    h2 {
        display: none;
    }
}

.game-nav{
    padding: 0 0 15px 0;

	h2{
		font-weight: bold;
		margin-bottom: 5px;
	}

	li{
		margin: 0 15px 0 0;
		float: left;
	}

	a{
		color: #000;
	}

	.running-game-link{
		color: #000;
		display: inline-block;
		width: 18px;
		height: 18px;
		vertical-align: bottom;

		> span{
			display: none;
		}
	}

	.start-game-link{
		color: #000;
		display: inline-block;
		height: 18px;
		width: 18px;
		vertical-align: bottom;

		> span{
			display: none;
		}
	}
}

.content-nav{
	background: #ddd;
	border-bottom: 1px solid #bbb;
	overflow: hidden;
}

.heading-nav {
    ul {
        @include mixins.fontsize(16);
		margin-top: 10px;
		display: flex;
		justify-content: start;
		flex-wrap: wrap;
	}
	
    span,
    a {
		white-space: nowrap;
		padding: 10px 10px 10px 0;
    }
}

.admin-nav{
	margin-top: 15px;

	h2{
		display:none;
	}
	li{
		margin: 0 15px 0 0;
		float:left;
	}

	a{
		color: red;
	}
}