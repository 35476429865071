@use 'mixins';

.button--icon {
  border: none;
  background: transparent;
  display: inline-block;
  @include mixins.default-font;
  @include mixins.fontsize(10);
  padding: 4px 9px;
  text-align: center;

  i {
    display: block;
    @include mixins.fontsize(28, 10);
  }
}

.button__icon {
  width: 3em;
  height: 3em;
  margin: 0 auto;
}
