.bar{
	background: #eee;
	padding: 0 10px;

	a{
		display:block;
		padding: 10px 5px;
	}
}

.bar.running-game{
	background: gold;
	border-color: goldenrod;
}