@use "mixins";

.table-list{
    margin-bottom: 10px;
}

.table-list__column-header{
	padding: 0;
	text-align: left;
	vertical-align: bottom;
	@include mixins.fontsize(12);
	text-transform: uppercase;
	font-weight: normal;
}

.table-list__column-header__content{
	padding: 10px 5px;
	display: block;
}

.table-list__row + .table-list__row{
    border-top: 1px solid #e7ede7;
}

.table-list__cell{
    vertical-align: top;
	padding: 5px;
}

.table-list__cell--numeric{
    text-align: right;
}