@use "mixins";

.simple-list{
	padding: 0;
    margin: 0;
	list-style-type: none;
}

.simple-list__item {
	padding: 5px 0;
}

.simple-list__item + .simple-list__item{
    border-top: 1px solid #e7ede7;
}